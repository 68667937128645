export default {
  primary: '#8D23E1',
  primary2: '#8D23E1',
  primary3: 'rgba(121,101,202,1)',
  background: '#fff',
  surface: '#f6f6f6',
  onBackground: '#eee',
  textStrong: '#444',
  textNormal: '#666',
  regular: '#555',
  degrade: 'linear-gradient(135deg, rgba(121,101,202,1) 0%, rgba(121,101,202,1) 30%, rgba(170,32,255,1) 100%)',
  white: '#fff',
  green: '#27AE60',
  active: '#07AE60',
  red: '#EB5757',
  blue: '#56CCF2',
  yellow: '#F2994A',
}