export const getQueryParam =(query)=>{
  const [,queries] = document?.location?.search?.split(query+'=')
  if(queries){
    const [query,] = queries?.split('&')
    return query
  }else{
    return null
  }
}
export const getTrackingHotmart =()=>{
  const utm_source = getQueryParam('utm_source')
  const utm_medium = getQueryParam('utm_medium')
  const utm_campaign = getQueryParam('utm_campaign')
  return [utm_source, utm_medium, utm_campaign].filter(Boolean).toString().replaceAll(',','|').slice(0,30)
}
export const getQueries =()=>{
  return document?.location?.search
}


export function mapTrackingToHotmart(url){

}

export function setParams(url, params) {
  const [baseUrl, queryString] = url.split('?');
  const currentParams = {};

  if (queryString) {
    queryString.split('&').forEach(param => {
      const [key, value] = param.split('=');
      currentParams[key] = decodeURIComponent(value);
    });
  }

  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value) {
      currentParams[key] = value;
    }
  });

  const updatedQueryString = Object.entries(currentParams)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');

  return updatedQueryString ? `${baseUrl}?${updatedQueryString}` : baseUrl;
}